import api from '../../api';
import i18n from '../../lang';

const state = () => ({
  roomItems: [
    {
      text: '1',
      value: 1,
    },
    {
      text: '2',
      value: 2,
    },
    {
      text: '3',
      value: 3,
    },
    {
      text: '4',
      value: 4,
    },
    {
      text: '5+',
      value: 5,
    },
  ],
  sizeItems: [
    {
      text: 'bis 40 m²',
      value: 'bis 40 m²',
    },
    {
      text: '41–60 m²',
      value: '41–60 m²',
    },
    {
      text: '61–80 m²',
      value: '61–80 m²',
    },
    {
      text: '81–100 m²',
      value: '81–100 m²',
    },
    {
      text: '101–125 m²',
      value: '101–125 m²',
    },
    {
      text: '126–150 m²',
      value: '126–150 m²',
    },
    {
      text: '151–200 m²',
      value: '151–200 m²',
    },
  ],
  useItems: [
    {
      text: 'self',
      value: 'Eigennutzer',
    },
    {
      text: 'capital',
      value: 'Kapitalanleger',
    },
  ],
  salutationItems: [
    {
      text: 'sir',
      value: 'Herr',
    },
    {
      text: 'madam',
      value: 'Frau',
    },
    {
      text: 'family',
      value: 'Familie',
    },
    {
      text: 'company',
      value: 'Firma',
    },
  ],
  titleItems: [
    {
      text: 'notitle',
      value: 'Kein Titel',
    },
    {
      text: 'doctor',
      value: 'Dr.',
    },
    {
      text: 'professor',
      value: 'Prof.',
    },
    {
      text: 'professor-doctor',
      value: 'Prof. Dr.',
    },
  ],
});
const getters = {
  getRoomItems: (state, getters, rootState, rootGetters) => {
    return state.roomItems;
  },
  getSizeItems: (state, getters, rootState, rootGetters) => {
    return state.sizeItems;
  },
  getUseItems: (state, getters, rootState, rootGetters) => {
    return state.useItems;
  },
  getSalutationItems: (state, getters, rootState, rootGetters) => {
    return state.salutationItems;
  },
  getTitleItems: (state, getters, rootState, rootGetters) => {
    return state.titleItems;
  },
};
const mutations = {
};
const actions = {

  submit ({state, commit, dispatch}, {formData, lang, preset, action = 'email'}) {
    return Promise.resolve()
      .then(() => {
        const data = {
          __preset__: preset,
          __from__: formData['email'].value,
          __date__: i18n.d(new Date(Date.now()).getTime(), 'shortDate'),
          __time__: i18n.d(new Date(Date.now()).getTime(), 'time'),
        };
        // add user data
        Object.keys(formData).forEach(key => {
          if (formData[key].value == null) {
            data[key] = '';
          } else if (typeof formData[key].value === 'object') {
            data[key] = formData[key].value.join(',');
          } else {
            data[key] = formData[key].value;
          }
        });
        return api.submit(action, data, lang);
      })
      .then(() => {
        return Promise.resolve({status: state.status});
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};

