let trackingEnabled = false;
let noTrackingUrl = window['analytics_no_tracking_url'];

export function hasPiwikLoaded (timeout) {
  if (window['analytics_tracking'] === true && noTrackingUrl !== document.location.origin) {
    const start = Date.now();
    showDebugMessage('start');
    return new Promise(resolve => {
      if (window.Piwik && window.Piwik.initialized === true) {
        trackingEnabled = true;
        showDebugMessage('resolve');
        resolve();
      } else {
        const intervalId = setInterval(() => {
          if (window.Piwik && window.Piwik.initialized === true) {
            trackingEnabled = true;
            showDebugMessage('ready');
            clearInterval(intervalId);
            resolve();
          }

          const now = Date.now();
          const elapsed = now - start;

          if (elapsed > timeout) {
            showDebugMessage('timeout');
            clearInterval(intervalId);
            resolve();
          }
        }, 50);
      }
    });
  }
}

/**
 * @param {Array} data
 */
function pushData (data) {
  showDebugMessage(`push data: ${data}`);
  if (trackingEnabled) {
    if (window._paq != null) {
      window._paq.push(data);
    }
  }
}

/**
 * @param {string} category
 * @param {string} action
 * @param {string=} name
 * @param {number=} value
 * @param {Object=} dimensions
 */
function trackEvent (category, action, name, value, dimensions) {
  pushData(['trackEvent', category, action, name, value, dimensions]);
}

/**
 * @param {string} url
 * @param {string} title
 */
export function trackCustomUrl (url, title) {
  setTimeout(() => {
    pushData(['setDocumentTitle', title]);
    pushData(['setCustomUrl', url]);
    pushData(['trackPageView']);
  }, 50);
}

/**
 * @param {string} action
 *
 * action can be show, select_list, select_overview, select_direct
 */
export function trackUnit (action) {
  trackEvent(
    'unit',
    action,
  );
}

/**
 * @param {string} action
 * @param {string} name
 *
 * action can be floorplan, pdf, video, still, 360, gallery, download
 * name can be {filename}, {cameraname}, {extra}
 */
export function trackMedia (action, name) {
  trackEvent(
    'media-website',
    action,
    name,
  );
}

/**
 * @param {string} action
 * @param {string} name
 *
 * action should be send, view, focus, error, page, selection
 * name should be formname, formname, inputfiledname, {formname}$${errorname} ($$ as delimiter between the vars), step_number (for multiforms), inputfieldvalue
 */
export function trackForm (action, name) {
  trackEvent(
    'form-website',
    action,
    name,
  );
}

/**
 * @param {string} websiteUnitSize
 * @param {string} websiteUnitRooms
 */
export function setWebsiteCustomDimensions (websiteUnitSize, websiteUnitRooms) {
  pushData(['setCustomDimension', 12, websiteUnitSize]); // website-unit-size
  pushData(['setCustomDimension', 13, websiteUnitRooms]); // website-unit-rooms
}

/**
 * @param {string} websiteContent
 */
export function setWebsiteCustomDimensionContent (websiteContent) {
  pushData(['setCustomDimension', 14, websiteContent]); // website-content
}

/**
 * @param {string} lang
 */
export function setLanguageDimension (lang) {
  pushData(['setCustomDimension', 15, lang]); // language
}

/**
 * @param {string} category
 * @param {string} action
 * @param {string} name
 */
export function trackClick (category, action, name) {
  trackEvent(
    category,
    action,
    name,
  );
}

/**
 * @param {string} name
 */
export function trackSlider (name) {
  trackEvent(
    'slide',
    'change view',
    name,
  );
}

/**
 * @param {string} name
 *
 * name should be #URL-Segment from Section
 */
export function trackContent (name) {
  setWebsiteCustomDimensionContent(name);
  trackEvent(
    'content',
    'view',
    name,
  );
}

/**
 * @param {string} action
 * @param {string} name
 */
export function trackDetail (action, name) {
  trackEvent(
    'detail',
    action,
    name,
  );
}

/**
 * @param {string} action
 * @param {string} name
 */
export function trackMap (action, name) {
  trackEvent(
    'map',
    action,
    name,
  );
}

/**
 * @param {string} unitname
 * @param {string} unitbaseprice
 * @param {string} unitareasize
 * @param {string} unitrooms
 * @param {string} unittype
 * @param {string} unitfloor
 * @param {string} city
 * @param {string} unitid
 */
export function setCustomDimensions (unitname, unitbaseprice, unitareasize, unitrooms, unittype, unitfloor, city, unitid) {

  let unitpriceperqm = null;
  if (unitbaseprice != null && unitareasize != null) {
    unitpriceperqm = unitbaseprice / unitareasize;
  }

  pushData(['setCustomDimension', 1, unitname]); // unit-name
  pushData(['setCustomDimension', 2, unitbaseprice]); // unit-price
  pushData(['setCustomDimension', 3, unitpriceperqm]); // unit-price-perqm
  pushData(['setCustomDimension', 4, unitareasize]); // unit-size
  pushData(['setCustomDimension', 5, unitrooms]); // unit-rooms
  pushData(['setCustomDimension', 6, unittype]); // unit-type
  pushData(['setCustomDimension', 7, unitfloor]); // unit-floor
  pushData(['setCustomDimension', 8, city]); // unit-city
  pushData(['setCustomDimension', 10, unitid]); // unit-id
}

/**
 * set the piwik pro js tracker object to get datas like visitor id
 */
export function setTracker () {
  showDebugMessage('set Tracker in Window Object');
  if (trackingEnabled) {
    window['tracker'] = Piwik.getTracker('inreal.piwik.pro', window['website_id']);
  }
}

/**
 * set user id which is the analytics visitor id
 */
export function setUserId () {
  let userId = getAnalyticsVisitorId();
  window['pk_id'] = userId;

  showDebugMessage(`set User Id ${userId}`);
  pushData(['setUserId', userId]);
}

/**
 * @param {string} email
 * @param {string} firstname
 * @param {string} lastname
 * @param {string} phone
 * @param {string} zip
 * @param {string} street
 * @param {string} city
 * @param {string} salutation
 * @param {string} appuserid
 * set Userdata in Piwik and/or in backend and userId if not set before
 */
export async function setAppUserData (email, firstname = '', lastname = '', phone = '', zip = '', street = '', city = '', salutation = '', appuserid = null) {
  let complianceSettings = await getComplianceSettings();
  showDebugMessage('Check App User');
  showDebugMessage(trackingEnabled);
  showDebugMessage(`${complianceSettings}`);
  if (trackingEnabled && complianceSettings === 1) {

    showDebugMessage('Set App User Data');
    if (phone == null) {
      phone = '';
    }

    if (salutation == null) {
      salutation = '';
    }

    // Update Piwik Pro User Attributes
    ppms.am.api('updateAttributes', {email, firstname, lastname, phone, zip, street, city, salutation});

    // make Call to Laravel api route to set AppUserData in Backend
    if (appuserid != null) {
      let url = `api/v1/inreal-cloud/userid?appuserid=${appuserid}&piwikUserId=${window['pk_id']}`;

      let xmlHttp = new XMLHttpRequest();
      xmlHttp.onreadystatechange = function () {
        if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
          //
        }
      };

      xmlHttp.open('GET', url, true);
      xmlHttp.send(null);
    }

    localStorage.setItem('get_user', '0');
    localStorage.removeItem('configInitialData');
  }
}

/**
 * @returns {Promise<void>}
 * get User Attributes from Piwik and generate the json user Attrbutes structure
 * on first visit with no cookies they will fail
 * so this function will call 2 times
 */
export async function getUserAttribute () {
  showDebugMessage('get User Attribute');
  if (trackingEnabled) {

    const data = await new Promise(resolve => {
      window.ppms.am.api('getAttributes', data => resolve(data), error => resolve('error'));
    });

    if (data !== 'error') {
      showDebugMessage(`User Id: ${data['attributes']['user_id']}`);
    } else {
      showDebugMessage('First Visit');
      setUserId();
      let ids = [];
      ids.push(window['pk_id']);
      updateUserJsonAttributes('user_id', JSON.stringify(ids));
    }
  }
}

/**
 * @returns {*}
 * get Analytics visitor id from Piwik Js Tracker Object
 */
export function getAnalyticsVisitorId () {
  if (window['tracker']) {
    let visitor = window['tracker'].getVisitorId();
    showDebugMessage(`Get Visitor ID ${visitor}`);
    return visitor;
  }
}

/**
 * @param {string} message
 */
function showDebugMessage (message) {
  if (window['analytics_debug'] === true) {
    console.warn(message);
  }
}

/**
 * @param {string} mainAttribute
 * @param {string} data
 * Updates Piwik User Json Attributes Structure
 */
function updateUserJsonAttributes (mainAttribute, data) {
  if (trackingEnabled) {
    ppms.am.api('updateAttributes', {[mainAttribute]: data});
  }
}

/**
 *
 * @returns {Promise<number>}
 */
async function getComplianceSettings () {
  if (trackingEnabled) {
    const compliance = await new Promise(resolve => {
      window.ppms.cm.api('getComplianceSettings', data => resolve(data), error => resolve('error'));
    });
    if (compliance === 'error') {
      return -1;
    } else {
      if (Object.keys(compliance.consents).length === 0) {
        return -1;
      } else {
        return parseInt(compliance.consents.analytics.status, 10);
      }
    }
  }
}

/**
 * set Tracking on true when user doenst have decline it
 * it will be used to set Consenst when user will register or loged in
 */
export async function setComplianceSettings () {
  const settings = await getComplianceSettings();
  if (trackingEnabled && settings !== 0) {
    const compliance = await new Promise(resolve => {
      window.ppms.cm.api('setComplianceSettings', {consents: {analytics: {status: 1}}}, data => resolve(data), error => resolve('error'));
    });
    showDebugMessage(`set compliance Settings ${compliance}`);
  }
}

export function hideLayer () {
  if (trackingEnabled) {
    showDebugMessage('hide layer');
    if (document.getElementsByClassName('cf-consent-manager')[0] != null) {
      document.getElementsByClassName('cf-consent-manager')[0].setAttribute('style', 'display:none !important');
    }
  }
}
