import Vue from 'vue';
import Vuex from 'vuex';

import app from './modules/app';
import form from './modules/form';
import nav from '../components/structure/navigation/navigation.modules';

Vue.use(Vuex);
const plugins = [];

export default new Vuex.Store({
  plugins,
  modules: {
    app,
    form,
    nav,
  },
});
