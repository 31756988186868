import Vue from 'vue';
import Router from 'vue-router';
import store from '../store';

import api from '../api';
import meta from '../api/plugins/meta';
// import analytics from '@/plugins/analytics';

import {hasKey} from '../lib/core/js/collections';
import {pushIfNotPresent} from '../lib/core/js/array';
import {ltrim, toUri} from '../api/utils/convert';
import {isString} from '../api/utils/validate';

import CwBase from '../components/templates/cw_base.vue';
import {trackCustomUrl} from '../tracking';

Vue.use(Router);

const templates = {
  'default': () => import('../components/templates/cw_default'),
};

const pages = {
  'home': () => import('../components/pages/cw_page_home'),
  'default': () => import('../components/pages/cw_page_default'),
  'news': () => import('../components/pages/cw_page_news'),
  'map': () => import('../components/pages/cw_page_map'),
  'contact': () => import('../components/pages/cw_page_contact'),
  'content': () => import('../components/pages/cw_page_content'),
  'delete': () => import('../components/pages/cw_page_delete'),
  'error': () => import('../components/pages/cw_page_error'),
};

class RouterService {
  constructor () {
    this.Instance = null;
  }

  component () {
    this.Instance = new Router({
      mode: 'history',
      base: '/',
      linkActiveClass: '',
      linkExactActiveClass: '',
      routes: [
        {
          path: '/',
          name: 'init',
          component: CwBase,
          redirect: this._getHomeUri(),
          children: [
            {
              // just to make incomplete routes with only lang work
              // (doesn't help for default langauges with no slug, which is correct!)
              path: this._getLangSlug(false),
              name: 'lang',
              redirect: this._getHomeUri(),
            }, {
              path: `${this._getLangSlug(true)}${this._getHomeSlug()}/:sub?`,
              name: 'home',
            }, {
              path: `${this._getLangSlug(true)}/:slug(.+)`,
              name: 'default',
              // Rewirte route path for api request
              // meta: {
              //   api: '/bar' (without lang)
              // }
            },
          ],
        },
      ],
    });

    this.Instance.beforeEach((to, from, next) => {

      if (store.getters['inreal/kirbyApi/getSiteData'] && store.getters['inreal/kirbyApi/getSiteData'].pages.dataprotection && store.getters['inreal/kirbyApi/getSiteData'].pages.dataprotection.value === to.path) {
        store.commit('inreal/consentManager/open', {mode: 'full-text'});
        if (from.name == null) {
          next({path: '/de/home'});
        } else {
          return;
        }
      }

      if (this._getSiteError()) {
        next();
        return;
      }

      // normalize navigation options
      // we use params.slug, because we need url without language
      let nav = {
        to: {
          path: hasKey(to.meta, 'api') ? to.meta.api : to.params.slug,
          lang: this._detectLanguageFromRoute(to),
          hash: ltrim(to.hash, '#'),
          name: to.name,
        },
        from: {
          path: hasKey(from.meta, 'api') ? from.meta.api : from.params.slug,
          lang: this._detectLanguageFromRoute(from),
          hash: ltrim(from.hash, '#'),
          name: from.name,
        },
      };
      // no request if route or query didn't change (hash not observed!)
      if (
        nav.to.path === nav.from.path &&
        nav.to.lang === nav.from.lang &&
        JSON.stringify(to.query) === JSON.stringify(from.query)) {
        if (nav.to.hash !== nav.from.hash) {
          this._scroll(nav);
        }
        next();
        return;
      }

      Promise.resolve()
        .then(() => {
          return store.dispatch('inreal/kirbyApi/loadLanguage', {lang: nav.to.lang});
        })
        .then(() => {
          return api.node(nav.to.path, to.query);
        })
        .then(response => {
          response.pagekey = nav.to.path;
          store.commit('inreal/kirbyApi/setPageData', response);
          this._setPageMeta();
          this._scroll(nav);
          trackCustomUrl(window.location.origin + to.fullPath, response.data.tracking.title);
          next();
        })
        .catch(response => {
          if (!hasKey(response, 'status')) {
            response.status = 1000;
          }
          response.pagekey = nav.to.path;
          store.commit('inreal/kirbyApi/setPageData', response);
          this._scroll(nav);
          next();
        });
    });

    this.Instance.home = () => {
      return this._getHomeUri();
    };

    this.Instance.getTemplate = blueprint => {
      return this._getTemplate(blueprint);
    };

    this.Instance.getPage = blueprint => {
      return this._getPage(blueprint);
    };

    this.Instance.getBlueprint = blueprint => {
      return this._getBlueprint(blueprint);
    };

    this.Instance.loadModal = slug => {
      this._loadModal(slug);
    };

    return this.Instance;
  }

  /*
  |--------------------------------------------------------------------------
  | Helper
  |--------------------------------------------------------------------------
  */

  _scroll (nav) {
    let scrollTo = nav.to.hash ? nav.to.hash : 0;
    let initial = true;
    if (nav.from.name) {
      initial = false;
    }
    store.commit('app/setScrollTarget', {scrollTo, initial});
  }

  /**
   * Load a template
   * @param {string} blueprint, optional
   * @returns {Component}
   */
  _getTemplate (blueprint) {
    blueprint = this._getBlueprint(blueprint);
    return templates[blueprint] || templates.default;
  }

  /**
   * Load a page
   * @param {string} blueprint, optional
   * @returns {Component}
   */
  _getPage (blueprint) {
    blueprint = this._getBlueprint(blueprint);
    return pages[blueprint] || pages.default;
  }

  /**
   * Helper
   * @param {string} blueprint, optional
   * @returns {string}
   */
  _getBlueprint (blueprint) {
    if (this._getPageError()) {
      blueprint = 'error';
    } else if (!isString(blueprint)) {
      blueprint = this._getPageBlueprint();
    }
    return blueprint.toLowerCase();
  }

  /**
   * Load Modal node
   * @param {string} slug
   */
  _loadModal (slug) {
    Promise.resolve()
      .then(() => {
        return api.node(slug, {});
      })
      .then(response => {
        store.commit('inreal/kirbyApi/setModalData', response);
      })
      .catch(response => {
        console.log('could not get modal data', response);
        store.commit('inreal/kirbyApi/setModalData', response);
      });
  }

  /**
   *
   * @param {bool} optional, add optional quantifier (?), if a language without a slug exists
   * @returns {string}
   */
  _getLangSlug (optional) {
    let reg = [];
    let quantifier = '';
    const languages = this._getLanguages();
    Object.values(languages).forEach(language => {
      if (language.slug) {
        reg.push(language.slug);
      } else if (optional) {
        quantifier = '?';
      }
    });
    if (reg.length > 0) {
      return `/:lang(${reg.join('|')})${quantifier}`;
    } else {
      return '';
    }
  }

  _getSiteData () {
    return store.getters['inreal/kirbyApi/getSiteData'];
  }

  _getSiteError () {
    return store.getters['inreal/kirbyApi/getSiteError'];
  }

  _getSiteStatus () {
    return store.getters['inreal/kirbyApi/getSiteStatus'];
  }

  _getPageData () {
    return store.getters['inreal/kirbyApi/getPageData'];
  }

  _getPageError () {
    return store.getters['inreal/kirbyApi/getPageError'];
  }

  _getPageBlueprint () {
    return store.getters['inreal/kirbyApi/getPageBlueprint'];
  }

  _getPageStatus () {
    return store.getters['inreal/kirbyApi/getPageStatus'];
  }

  _getLangKey () {
    return store.getters['inreal/kirbyApi/getLangKey'];
  }

  _getIsMultiLanguage () {
    return store.getters['inreal/kirbyApi/getIsMultiLanguage'];
  }

  _getCurrentLanguage (property = null) {
    if (property != null) {
      return store.getters['inreal/kirbyApi/getCurrentLanguage'](property);
    } else {
      return store.getters['inreal/kirbyApi/getCurrentLanguage']();
    }
  }

  _getLanguages () {
    return store.getters['inreal/kirbyApi/getLanguages'];
  }

  _getHomeSlug () {
    if (this._getSiteError()) {
      return '/:slug(error)';
    }
    let reg = [];
    const languages = this._getLanguages();
    Object.values(languages).forEach(language => {
      pushIfNotPresent(reg, language.homeslug);
    });
    // reg = fn.unique(reg);
    return `/:slug(${reg.join('|')})`;
  }

  _getHomeUri () {
    const uri = toUri(
      this._getCurrentLanguage('slug'),
      this._getCurrentLanguage('homeslug'),
    );
    return uri;
  }

  _setPageMeta () {
    if (this._getPageStatus() !== 200) {
      meta.setError(this._getPageStatus());
    } else if (this._getSiteStatus() !== 200) {
      meta.setError(this._getSiteStatus());
    } else {
      meta.setPage(this._getSiteData(), this._getPageData(), this._getLangKey());
    }
  }

  _detectLanguageFromRoute (route) {

    if (!this._getIsMultiLanguage()) {
      return this._getCurrentLanguage();
    }

    if (hasKey(route.params, 'lang')) {
      return route.params.lang;
    }

    // in multilang sites and no lang-param in url, the language is the one without slug
    let res = null;
    const languages = this._getLanguages();
    Object.keys(languages).forEach(key => {
      const language = languages[key];
      if (!language.slug) {
        res = key;
      }
    });
    return res;
  }
}

export default new RouterService();
