import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

const opts = {
  theme: {
    light: true,
    themes: {
      light: {
        // Keep in sync with src/styles/_variables.sass !!!
        primary: '#73226e',
        secondary: '#28282A',
        accent: '#fdc210',
        background: '#ffffff',
        positive: '#a6cc38',
        negative: '#ef4d4b',
        error: '#ff8d8d',
        backgroundDarken: '#28282A',
        bright: '#ffffff',
      },
    },
  },
  icons: {
    iconfont: 'mdi',
  },
};

export default new Vuetify(opts);
