
const state = () => ({
  scrollTarget: {
    value: 0,
    initial: true,
  },
  breakpoints: null,
  isTouchDevice: false,
  documentWidth: null,
  documentHeight: null,
});
const getters = {
  getScrollTarget: (state, getters, rootState, rootGetters) => {
    return state.scrollTarget;
  },
  getBreakpointKey: (state, getters) => {
    const keys = Object.keys(state.breakpoints);
    const matches = keys.filter(key => {
      const value = state.breakpoints[key];
      return value < state.documentWidth;
    });
    if (matches.length > 0) {
      return matches.shift();
    } else {
      if (state.documentWidth < state.breakpoints[keys[0]]) {
        return keys.pop();
      } else {
        return keys.shift();
      }
    }
  },
  getBreakpointValue: (state, getters) => {
    const values = Object.values(state.breakpoints);
    const matches = values.filter(value => {
      return value < state.documentWidth;
    });
    if (matches.length > 0) {
      return matches.shift();
    } else {
      if (state.documentWidth < values[0]) {
        return values.pop();
      } else {
        return values.shift();
      }
    }
  },
  getDocumentRatio: state => {
    return state.documentWidth / state.documentHeight;
  },
  getDocumentWidth: state => {
    return state.documentWidth;
  },
  getDocumentHeight: state => {
    return state.documentHeight;
  },
  getIsTouchDevice: state => {
    return state.isTouchDevice;
  },
};
const mutations = {
  setScrollTarget (state, {scrollTo, initial}) {
    state.scrollTarget.value = scrollTo;
    state.scrollTarget.initial = initial;
  },
  setBreakpoints (state, breakpoints) {
    state.breakpoints = breakpoints;
  },
  setDocumentWidth (state) {
    state.documentWidth = Math.max(
      document.documentElement['clientWidth'],
      document.body['scrollWidth'],
      document.documentElement['scrollWidth'],
      document.body['offsetWidth'],
      document.documentElement['offsetWidth'],
    );
  },
  setDocumentHeight (state) {
    state.documentHeight = Math.max(
      document.documentElement['clientHeight'],
      document.body['scrollHeight'],
      document.documentElement['scrollHeight'],
      document.body['offsetHeight'],
      document.documentElement['offsetHeight'],
    );
  },

};
const actions = {
  updateDocumentDimension ({commit, dispatch, getters, rootGetters, state, rootState}) {
    commit('setDocumentWidth');
    commit('setDocumentHeight');
  },
  updateIsTouchDevice (state) {
    const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    let mq = function (query) {
      return window.matchMedia(query).matches;
    };

    if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
      state.isTouchDevice = true;
    } else {
      // include the 'heartz' as a way to have a non matching MQ to help terminate the join
      // https://git.io/vznFH
      const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
      state.isTouchDevice = mq(query);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};

